.chatBox {
  display: flex;
  height: 100vh;
  // padding: 0 0 115px;
  // padding: 0 0 75px;
  background-size:100%;
  overflow:auto;
}

.dialogBox {
  flex: 1;
  overflow-y: scroll;
  padding: 0 5%;
}

.bubbleItem {
  width: 100%;
  display: flex;
  margin: 10px 0 10px;
}

.bubbleItemRight {
  flex-direction: row-reverse;
  padding-left: 40px;
}

.bubbleItemLeft {
  padding-right: 45px;
}

.bubble {
  border-radius: 5px;
  /* margin-left: 15px; */
  padding: 10px;
  font-size: 16px;
  line-height: 1.2;
  word-wrap: break-word;
  word-break: break-all;
}

.rightBubble {
  position: relative;
  /* margin-right: 1rem; */
  float: right;
  background-color: #4371ff;
  color: #fff;
}

.rightBubble:after {
  content: '';
  position: absolute;
  top: 1rem;
  height: 12px;
  width: 12px;
  right: -12px;
  border: 6px solid transparent;
  border-left-color: #4371ff;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: " ";
}
.clearfix:after {
    clear: both;
}

.panelTitle {
  display:flex;
  align-items: center;
  color:#4285ff;
  font-weight:600
}

.quickAskItem {
  display: inline-block;
  margin-right:10px;
  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 13px;
  color: #4285ff;
  font-weight: 600;
}

.navigation {
  font-size: 14px;
  padding:12px 0;
  border-top:1px solid #f0f0f0;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.foldOrNotButton{
  font-size: 13px;
  font-weight:600;
  padding:7px 12px;
  border-radius:4px;
  margin-bottom:10px;
  color:#4285ff;
  background-color:#f1f7ff;
  cursor: pointer;
}
.myVideoPlayer{
  overflow: hidden;
  // height:100vw * (190/375);
  height:50vw;
  :global{
      .video-react{
          // margin-top:100vw * (1280/720) * -0.2;
          // background: url(../images/video-bg.png) no-repeat;
          // background-size: 100% auto;
          // overflow: hidden;
          // >video{
          //   margin-top:100vw * (1280/720) * -0.2
          // }
          .video-react-loading-spinner{
            display: none;
          }
          .video-react-button{
              display:none
          };
          .controlBar{
              display:none
          }
          // .video-react-poster{
          //   background-position:0% 0%;
          // }
      };
  }
}
.myAnchorButton{
  position:fixed;
  right:10px;
  // top:10px;
  z-index:999;
  -webkit-transform:translateZ(999px);
  -moz-transform:translateZ(999px);
  -o-transform:translateZ(999px);
  transform: translateZ(999px);
  :global{
    .ant-btn, .ant-btn-link:hover, .ant-btn-link::selection, .ant-btn-link:active, .ant-btn-link:focus{
      background: #7c7d7d;
      color:#fff
    }
  }
}
.myGuideModal{
  :global{
    .ant-modal-content{
      background: linear-gradient(160deg, #8db9ff, #4877ff );
      color:#fff;
      .ant-modal-body{
        padding:20px 20px 0px
      }
      .ant-modal-footer{
        border-top:none
      }
    }
    .ant-btn-link, .ant-btn-link:hover{
      color:#ceddff
    }
  }
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;