.quickAskDiv {
    // position: fixed;
    position:absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    padding: 7px 5%;
    overflow-x: scroll;
    white-space:nowrap;
    font-size: 12px;
    line-height:1
}
.maskQuickAskDiv{
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    padding: 7px 5%;
    white-space:nowrap;
    font-size: 12px;
    line-height:1;
    z-index:1001;
    background-color: #f7f9fa;
    pointer-events: none;
    border-radius: 30px;
    overflow-x: hidden;
    padding:7px calc(5vw - 10px);
    margin:0px 10px
}

.quickAskDiv::-webkit-scrollbar {
    display: none;
}

.quickAskItem {
    display: inline-block;
    margin-right:10px;
    padding: 8px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 13px;
    color: #4285ff;
    font-weight: 600;
}

.selfIntroBtn {
    display: inline-block;
    vertical-align: top;
}
@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;