
.bubbleItem {
    width: 100%;
    display: flex;
    margin: 30px 0 10px;
}

.bubbleItemRight {
    flex-direction: row-reverse;
    padding-left: 40px;
}

.bubbleItemLeft {
    padding-right: 45px;
}

.botAvatar img {
    width: 38px;
    box-sizing: border-box;
    height: 38px;
}

.bubble {
    border-radius: 5px;
    /* margin-left: 15px; */
    padding: 10px;
    font-size: 16px;
    line-height: 1.2;
    word-wrap: break-word;
    word-break: break-all;
}

.leftBubble {
    position: relative;
    background-color: #fff;
    border-top: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
    border-left: 2px solid #FFFFFF

}

.leftBubble:before {
    content: '';
    position: absolute;
    top: 1rem;
    left: -12px;
    height: 12px;
    width: 12px;
    border: 6px solid transparent;
    border-right-color: #fff;
}

.rightBubble {
    position: relative;
    /* margin-right: 1rem; */
    float: right;
    background-color: #4371ff;
    color: #fff;
}

.rightBubble:after {
    content: '';
    position: absolute;
    top: 1rem;
    height: 12px;
    width: 12px;
    right: -12px;
    border: 6px solid transparent;
    border-left-color: #4371ff;
}

table, thead, tbody, th, td, tr {
    display: block;
}
thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

td {
    border: none;
    position: relative;
}

td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
}

.remarkDiv {
    margin-left: 10px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    display: -webkit-flex; 
    justify-content:flex-end;
}

.remarkItem {
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    display: inline-block;
}

.remarkItem i {
    vertical-align: middle;
}

.foldOrNotButton{
    font-size: 13px;
    font-weight:600;
    padding:7px 12px;
    border-radius:4px;
    margin-bottom:10px;
    color:#4285ff;
    background-color:#f1f7ff
}
.foldBubble {
    position: relative;
    padding-bottom: 40px;
}

.unfoldBubble {
    .loadMore {
        display: none;
    }
}
.unfoldBubble {
    .loadLess {
        display: none;
    }
}

.foldBubble {
    .loadMore {
        text-align: center;
        position: absolute;
        height: 45px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px 0px;
        border-radius: 0 0 5px 5px;
        background-color: #fff;
        color: #3C6BC0;
        font-size: 14px;
        background: linear-gradient(to bottom, rgba(255,255,255,0), #fff 40%)
    }
    .loadLess {
        text-align: center;
        position: absolute;
        height: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 0px;
        border-radius: 0 0 5px 5px;
        background-color: #fff;
        color: #3C6BC0;
        font-size: 14px;
    }
    .overflowBox {
        overflow: hidden;
    }
}
.overflowBox {
    word-break: break-all !important;
    word-wrap:break-word !important;
    white-space: normal !important;
    :global{
        .react-mde{
            border:none !important;
            .mde-header{
                display: none;
            }
            .mde-preview{
                min-height:20px !important;
                .mde-preview-content{
                    padding:0px;
                    >p{
                        margin-bottom: 0px !important;
                        >img{
                            // max-width:calc(90vw - 76px)
                            max-width: 100%
                        };
                        >a{
                            word-break: break-all !important;
                            word-wrap:break-word !important;
                            white-space: normal !important;
                        }
                    }
                }
            }
        }
    }
}
.playIconDiv{
    width:28px;
    height:28px;
    background-color: #fff;
    border-radius:14px;
    padding:3px
}
.maskPlayIconDiv{
    width:28px;
    height:28px;
    background-color: #fff;
    z-index:1001;
    border-radius:14px;
    pointer-events: none;
    padding:3px
}
.feedbackIconDiv{
    width:24px
}
.maskFeedbackIconDiv{
    width:24px;
    height:24px;
    background-color: #fff;
    z-index:1001;
    border-radius:12px;
    pointer-events: none;
}
.feedbackTitle{
    padding-left:8px;
    border-left:2px solid rgba(51, 112, 255, 0.6);
    color: rgba(51, 112, 255, 0.6);
}
.noticeTitle{
    font-weight: 600;
    font-size: 16px;
    color: #207DFE;
}
.welcomeTitle{
    color:#5C92FF;
    font-weight: 600;
}
.titleDiv{
    display: flex;
    word-break: break-all !important;
    word-wrap:break-word !important;
    white-space: normal !important;    
}
.roundContentDrawer{
    :global{
        .ant-drawer-content{
            border-radius: 15px 15px 0px 0px;
        }
        .ant-drawer-header-no-title{
            padding:0px !important
        }
        .ant-drawer-close{
            width:45px;
            height:45px
        }
        .react-mde{
            .mde-preview{
                .mde-preview-content{
                    >p{
                        >img{
                            max-width:100% !important
                        };
                    }
                }
            }
        }
    
    }
    .leftBubble{
        border:0px;

    }
    .loadMore{
        display: none;
    }
    .titleDiv{
        margin-right:33px
    }
    .overflowBox{
        max-height: none !important;
        margin-bottom:0px !important
    }
    .bubble{
        padding:14px 14px 40px 14px;
        overflow: auto;
    }
}
.detailTitle{
    position: relative;
    font-size: 18px;
    font-weight:600;
    color: #232426;
    padding-left: 12px;
    height: 40px;
    line-height:40px;
  }
  .detailTitle::before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin:auto;
    width: 4px;
    height: 16px;
    background: #3370FF;
  }
  .flex1Div{
    flex:1;
    margin-right:5px;
    min-width: 0;
    max-width: calc(100vw - 102px);
    word-break: break-all !important;
    word-wrap:break-word !important;
    white-space: normal !important;
  }
  
@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;