.loginBg {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBox {
  padding: 50px 20px;
  border-radius: 15px;
  background-color: #f7f9fa;
  text-align: center;
  font-size: 16px;
}

.loginBtn {
  outline: none;
  width: 90%;
  margin-top: 30;
  color: #fff !important;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.loginBox {
  input {
    width: 95%;
    height: 40px;
    padding: 8px 15px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    min-width: 239px;
    outline: none;
  }
  :global {
    .ant-form-item-label {
      width: 45px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
    .ant-form-explain {
      font-size: 14px;
      color: #ff4d4f;
      padding: 3px 0px;
    }
  }
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;