.faqItem {
  font-size: 14px;
  padding: 12px 0;
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .faqItem a {
    text-decoration: none;
    display: flex;
} */

.faqItem a {
  display: flex;
  align-items: center;
  color: black;
}

.faqItem .dot {
  text-align: center;
}

.change {
  font-size: 14px;
  color: #3c6bc0;

  svg {
    vertical-align: sub;
  }
}

.panelTitle {
  display: flex;
  align-items: center;
  color: #4285ff;
  font-weight: 600;
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;