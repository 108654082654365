.drawerFooter {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    padding: 5px 15px;
    text-align: right;
}

.drawerContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 50px;
    padding: 0 20px 20px;
    overflow: scroll;
    font-size: 16px;
}

.drawerContent h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.ant-drawer-body {
    padding: 20px 15px 0;
}

.closeDrawerBtn {
    border: 1px solid #DCDCDC;
    padding: 5px 12px;
    border-radius: 24px;
    font-size: 12px;
    color: #787878;
    display: inline-block;
}

.keweiLogo {
    display: block;
    height: 25px;
    margin-bottom: 10px
}

.leyanLogo {
    margin: 20px 0 10px;
}

.selfintro p {
    line-height: 1.6;
}
.roundDrawer{
    :global{
        .ant-drawer-content{
            border-radius: 15px 15px 0px 0px;
        }
    }
}
@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;