.customTree {
  :global {
    .ant-tree li .ant-tree-node-content-wrapper {
      line-height: 16px;
      font-size: 16px;
      color: #3c6bc0;
    }
  }
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;