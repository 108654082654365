* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 12px;
  background-color: #f7f9fa !important
}

.chatBox {
  display: flex;
  height: 100vh;
  padding: 0 0 115px;
  /* background-color: #eee; */
  /* background:url("/images/bg@3x.png") no-repeat; */
  background-size: 100%;
  overflow: auto;
}

.dialogBox {
  flex: 1;
  overflow-y: scroll;
  padding: 0 5%;
}

.ant-message {
  top: auto !important;
  bottom: 100px;
}

.ant-message-notice-content {
  background: rgba(0, 0, 0, 0.5) !important;
}

.messageText {
  color: #fff;
}
.remarkDiv {
  margin-left: 10px;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  display: -webkit-flex; 
  justify-content:flex-end;
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;