.table {
  table {
    width: 60vw;
    text-align: left;
    margin-bottom: 10px;
    border-collapse: collapse;
  }

  td {
    padding: 6px;
    text-align: left;
  }

  tr + tr {
    border-top: 1px solid #e6e6e6;
  }

  td div {
    width: 100%;
    height: auto;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
}

.tableP + .tableP {
  margin-top: 10px;
}

.foldTd {
  position: relative;
  padding-bottom: 20px;
}

.unfoldTd svg,
.foldTd svg {
  margin-left: 5px;
  vertical-align: middle;
}

.unfoldTd .loadMore {
  display: none;
}

.foldTd .loadMore {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-size: 14px;
}

.foldTd .overflowBox {
  max-height: 45px;
  overflow: hidden;
}

.firstBold:nth-child(1) {
  font-weight: bold;
  padding-left: 10px;
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;