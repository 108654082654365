.entranceButton{
    position: fixed;
    height:30px;
    width:100%;
    left: 0px;
    top: 0px;
    font-size: 16px;
    font-weight:500;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EEF7FF;
    z-index: 999;
}
@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;