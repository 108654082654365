.dialogPanel {
    font-size: 16px;
    /* width: 90%; */
    margin: 0 auto 0;
    padding: 10px 15px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-radius: 5px;
    /* background-color: #fff; */
    background-image: linear-gradient(to bottom , #ecf3ff, #fff 30%);
}

.dialogPanel .navigation {
    /* font-size: 14px;
    padding: 10px 0;
    border-top: 1px solid #D8D8D8;
    display: flex;
    justify-content: space-between; */
    font-size: 14px;
    padding:12px 0;
    border-top:1px solid #f0f0f0;
    display:flex;
    justify-content: space-between;
    align-items: center;

}

.panelTitle {
    display:flex;
    align-items: center;
    color:#4285ff;
    font-weight:600
    /* padding-bottom: 10px; */
    /* border-bottom: 1px solid #D8D8D8; */
}

.navigation .dot {
    text-align: center;
}
.navigation a {
    display: flex;
    align-items: center;
    color:black
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;