.sendBox {
  // position: fixed;
  position:absolute;
  bottom: 18px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5%;
  height: 50px;
  align-items: center;
}

.formDiv {
  height: 46px;
  /* border: 1px solid #B4B4B4; */
  border-radius: 8px;
  display: flex;
  align-items: center;
 
  :global {
    .react-autosuggest__suggestion {
      font-size: 1.4rem;
      padding: 1rem 1rem;
      border-top: 1px solid #eee;
    }
  
    .react-autosuggest__suggestion--highlighted {
      background-color: #eee;
      color: #000;
    }
  
    .react-autosuggest__input {
      width: 100%;
      border: none;
      padding: 10px;
      font-size: 16px;
      overflow-y: visible;
      border-radius: 10px 0px 0px 10px;
      outline: none;
      height: 44px;
    }

    .react-autosuggest__input:focus {
      outline: none;
    }
  
    .react-autosuggest__container {
      position: relative !important;
      flex: 1;
    }
  
    .react-autosuggest__suggestions-container {
      position: absolute;
      padding: 0 5vw;
      bottom: 85px;
      left: -5vw;
      width:100vw;
      // right: -15vw;
      overflow: hidden;
    }

    .react-autosuggest__suggestions-container--open {
      border-top: 1px solid #eee;
      box-shadow: 0px -7px 12px -7px #d0c2c2;
      background-color: #f7f9fa;
      // border-radius: 5px;
    }
  }
}

.sendBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #fff;
  height: 30px;
  border-radius: 12px;
  outline: none;
  margin-right: 2px;
  margin-top: 1px;
}

.messageText {
  color: #fff;
}

@primary-color: #007fff;@success-color: #00bf69;@info-color: #3370ff;@warning-color: #f2a100;@error-color: #f05b5b;@body-background: #f5f5f7;